
<template>
  <div class="all">
    <!--    顶部Banner-->
    <div class="topTitle">
      <div class="title">
        <!--        顶部logo-->
        <div class="imgLogo" @click="home"><img src="../../assets/img/logo2.png" class="home">
          <p>环宇建筑科技股份有限公司</p>
        </div>
      </div>
      <div class="registered">
        <!--        注册框-->
        <div class="dRegistered">
          <div class="ph">
            <div v-for="(item,i) in tabs" :key="i.name" :class="{tab_textBut:tabs[i].msg==true}" class="tab_text"
                 @click="msg_but(i)">{{ tabs[i].name }}
            </div>
          </div>
          <div v-if="tabs[0].msg" class="zcD">
            <enterprise-reg></enterprise-reg>
          </div>
          <div v-if="tabs[1].msg" class="zcD">
            <private-reg></private-reg>
          </div>
        </div>
      </div>
      <div class="bm">@ 2021 环宇集团采招平台 版权所有 法律声明</div>
    </div>
  </div>
</template>

<script>
import enterpriseReg from '@/components/code/enterpriseReg'
import privateReg from '@/components/code/privateReg'
export default {
  name: 'registration',
  components: {
    enterpriseReg,
    privateReg
  },
  data () {
    return {
      tabs: [
        {
          name: '企业注册',
          msg: true
        },
        {
          name: '个人注册',
          msg: false
        }
      ],
      message: 0
    }
  },
  created () {
  },
  methods: {
    msg_but (i) {
      if (i === 0) {
        this.tabs[i].msg = true
        this.tabs[1].msg = false
      } else if (i === 1) {
        this.tabs[0].msg = false
        this.tabs[1].msg = true
      }
    },
    home () {
      this.$router.replace('/')
    }

  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.home {
  cursor: pointer;
}

.all {
  width: 100%;
  height: 1000px;
  margin: 0 auto;
  background-color: #f7f7f7;
}

/*顶部title*/
.topTitle {
  width: 100%;
  height: 300px;
  background-color: #ffffff;
  background-image: url("../../assets/img/regi.png");
  background-size: 100% 100%;
}

.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
}
.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}

.registered {
  width: 100%;
  /*height: 990px;*/
  /*background-color: blueviolet;*/
  /*text-align: center;*/
}

/*注册框*/
.dRegistered {
  margin: 100px auto;
  width: 1200px;
  /*height: 730px;*/
  background-color: #ffffff;
}

.ph {
  display: flex;
}

.tab_textBut {
  width: 100%;
  height: 10%;
  font-size: 16px;
  color: #10539B;
  text-align: center;
  line-height: 80px;
  letter-spacing: 1px;
  border-bottom: 1px solid #EEEEEE;
  font-weight: bold;
  cursor: pointer;
}

.tab_text {
  width: 100%;
  height: 10%;
  /*background-color: #10539B;*/
  font-size: 16px;
  text-align: center;
  line-height: 80px;
  letter-spacing: 1px;
  border-bottom: 1px solid #EEEEEE;
  cursor: pointer;
}
.zcD {
  margin: 20px auto;
  width: 80%;
  height: 590px;
}
.bm {
  /*background-color: #841818;*/
  text-align: center;
  font-size: 14px;
  color: #666666;
  margin-top: 50px;
  margin-left: 75px;
}
</style>
