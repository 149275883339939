<template>
  <!--  企业注册-->
  <div>
    <el-form :label-position="labelPosition" :model="formLabelAlign" label-width="100px">
      <el-form-item class="el_form" label="手机号码 :">
        <el-input v-model="formLabelAlign.phone" maxlength="11" placeholder="请输入您的手机号码"></el-input>
      </el-form-item>
      <el-form-item class="el_form" label="验证码 :">
        <el-input v-model="formLabelAlign.code" maxlength="4" placeholder="请输入您的验证码"></el-input>
        <span v-show="show" :datafld="!show" class="code_get" @click="code_time()">获取验证码</span>
        <span v-show="!show" class="code_get">{{ code }}秒后获取</span>
      </el-form-item>
      <el-form-item class="el_form" label="登录密码 :">
        <el-input v-model="formLabelAlign.pwd" :show-password="true" placeholder="请输入您的密码"></el-input>
      </el-form-item>
      <el-form-item class="el_form" label="确认密码 :">
        <el-input v-model="formLabelAlign.newPwd" :show-password="true" placeholder="请确认您的密码"></el-input>
      </el-form-item>
      <el-form-item class="validation_Code">
        <validation @childSuccess="textA"></validation>
      </el-form-item>
    </el-form>
    <div class="ht">
      <el-checkbox v-model="checked">我同意</el-checkbox>
      <span>《环宇集团采招平台须知》</span></div>
    <div class="login_d" @click="tep_btn(checked)">下一步</div>
  </div>
</template>

<script>
import axios from 'axios'
import { VtPhone, isPhoneCli } from '@/api/api'
import validation from '@/components/code/validation'

const TIME_COUNT = 60 // 验证码倒计时
export default {
  name: 'enterpriseReg',
  components: {
    validation
  },
  data () {
    return {
      validationS: false,
      labelPosition: 'right',
      formLabelAlign: {
        phone: '',
        code: '',
        pwd: '',
        newPwd: ''
      },
      code: '',
      show: true,
      timer: null,
      checked: false,
      message: 0,
      date: '',
      authCode: ''
    }
  },
  created () {
  },
  methods: {
    textA (val) {
      this.validationS = val
    },
    phone_gz () {
      if (!/^1[0-9]{10}$/.test(this.phone)) {
        this.phone = ''
        this.$message.error('请输入正确的电话号码')
      }
    },
    msg_but (i) {
      if (i === 0) {
        this.tabs[i].msg = true
        this.tabs[1].msg = false
      } else if (i === 1) {
        this.tabs[0].msg = false
        this.tabs[1].msg = true
      }
    },
    // 获取验证码
    code_time () {
      if (this.validationS === false) {
        this.$message.error('请先拖动滑块进行验证！')
      } else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.formLabelAlign.phone) || this.formLabelAlign.phone === '') {
        this.$message.error('请输入正确的手机号码！')
      } else {
        if (!this.timer) {
          this.code = TIME_COUNT
          this.show = false
          axios.defaults.headers.token = localStorage.getItem('token')
          VtPhone({
            method: 'authCode',
            phone: this.formLabelAlign.phone
          }, res => {
            this.$message.success('验证码已发送至您的手机！')
            this.message = res.data.data
          })
          this.timer = setInterval(() => {
            if (this.code > 0 && this.code <= TIME_COUNT) {
              this.code--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      }
    },
    // 下一步进入企业注册
    tep_btn (checked) {
      isPhoneCli({
        method: 'testPhone',
        phone: this.formLabelAlign.phone,
        authCode: this.formLabelAlign.code
      }, resPhone => {
        if (resPhone.data.success === true) {
          isPhoneCli({
            method: 'isPhone',
            phone: this.formLabelAlign.phone
          }, res => {
            // 1为已注册    0 为未注册
            if (res.data.data === '1') {
              this.$message.error('手机号已注册，不能重复注册')
            } else {
              if (this.formLabelAlign.phone === '' || this.formLabelAlign.phone === null) {
                this.$message.error('手机号码不能为空！')
              } else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.formLabelAlign.phone)) {
                this.$message.error('请输入正确的手机号码！')
              } else if (this.formLabelAlign.code === '' || this.formLabelAlign.code === null) {
                this.$message.error('请输入您的验证码！')
              } else if (this.formLabelAlign.pwd === '' || this.formLabelAlign.pwd === null) {
                this.$message.error('请输入您的账户密码！')
              } else if (this.formLabelAlign.newPwd === '' || this.formLabelAlign.newPwd === null) {
                this.$message.error('请输入您的确认登录密码！')
              } else if (this.formLabelAlign.newPwd !== this.formLabelAlign.pwd) {
                this.$message.error('登录密码和确认密码不同！')
              } else if (this.message !== this.formLabelAlign.code) {
                this.$message.error('请您输入正确的验证码！')
              } else if (this.validationS === false) {
                this.$message.error('请先拖动滑块进行验证！')
              } else if (checked === false) {
                this.$message.error('请同意《环宇集团采招平台须知》！')
              } else {
                localStorage.setItem('phone', this.formLabelAlign.phone)
                localStorage.setItem('pwd', this.formLabelAlign.pwd)
                this.$message.success('验证身份成功！')
                this.$router.replace('/login/registration_step')
              }
            }
          })
        } else {
          this.$message.error(resPhone.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>
/*登录按钮*/
.login_d {
  width: 200px;
  height: 50px;
  background-color: #10539B;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  color: white;
  margin-left: 44%;
  border-radius: 4px;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 40px;
}

.code_get {
  position: absolute;
  top: 2px;
  right: 1px;
  width: 140px;
  height: 38px;
  text-align: center;
  background-color: #EEEEEE;
  color: #666666;
  line-height: 37px;
  margin-left: -140px;
  cursor: pointer;
}

/*合同*/
.ht {
  width: 240px;
  font-size: 14px;
  color: #0D5091;
  margin-left: 42%;
  margin-top: 20px;
}

.bm {
  background-color: #f7f7f7;
  text-align: center;
  font-size: 14px;
  color: #666666;
}

.el_form {
  margin: 40px auto;
  width: 600px;
}

.validation_Code {
  width: 62.5%;
  height: 40px;
  /*background-color: #7ac23c;*/
  margin: 0 auto;
}
</style>
